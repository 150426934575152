import React, { useEffect, useState, useRef, createContext, useCallback } from 'react'
import { Modal } from 'antd';
import Form from './form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Subject } from 'rxjs';
import { Dimension } from 'types/dimensions';
import { useForm } from 'antd/es/form/Form';

export const formObservable = new Subject<{dimensions: Dimension[], trID: string}>();
export const ModalContext = createContext({dataRef: new Map<string, {id: number, dimension_value_id: number, dimension_id: number}[]>(), appliedRef: new Map<string, [boolean, {value: number; id: number;}]>(), updateTmpApplyList: (key, check, value) => {}});
const typeModelMap = {
  "Bill": ["bill", "bill_items"],
  "DebitNote": ["debit_note", "note_items"]
}

const DimesnionModal = ({ cdID, cdType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, modalContext] = Modal.useModal();
  const dataRef = useRef<Map<string, {id: number, dimension_value_id: number, dimension_id: number}[]>>(new Map());
  const appliedRef = useRef<Map<string, [boolean, {value: number, id: number}]>>(new Map())
  const tmpAppliedRef = useRef<Map<string, [boolean, {value: number, id: number}]>>(new Map())
  const activeDimensionList = useRef([]);
  const activeLineItem = useRef(null);
  const { t } = useTranslation();
  const [form] = useForm();

  const updateTmpApplyList = (dimensionID: string, applied: boolean, valueObj: {value: number, id: number}) => {
    tmpAppliedRef.current.set(dimensionID, [applied, {value: valueObj?.value, id: valueObj?.id}]);
  }

  const updateApplyList = () => {

    const appliedObj = tmpAppliedRef.current;
    for(const [k, v] of appliedObj.entries()) {
      if(v[0]) {
        appliedRef.current.set(k, v);
        for(const [key, list] of dataRef.current.entries()) {
          const tr = document.getElementById(key);
          const dimensionLi = tr?.querySelector('.line_item_dimension')
          const dimension = list.find(entry => String(entry.dimension_id) === k);
          if(Boolean(dimension)) {
            dimension.dimension_value_id = v[1].value;
          } else {
            list.push({id: undefined, dimension_value_id: v[1].value, dimension_id: Number(k)})
          }
          dimensionLi.classList.add('line-item-has-applied');
        }
      } else {
        appliedRef.current.delete(k);
      }
    }
  }

  const parentFormSubmitHandler = useCallback(function({ formData }: FormDataEvent) {
    for(const [key, value] of dataRef.current.entries()){
      for(let i = 0; i < value.length; i++) {
        const { dimension_value_id, id, dimension_id } = value[i];
        if(dimension_value_id || id) {
          formData.append(`${typeModelMap[cdType][0]}[${typeModelMap[cdType][1]}_attributes][${key}][dimension_value_assignables_attributes][${i}][dimension_id]`, String(dimension_id));
          if (Boolean(dimension_value_id)) {
            formData.append(`${typeModelMap[cdType][0]}[${typeModelMap[cdType][1]}_attributes][${key}][dimension_value_assignables_attributes][${i}][dimension_value_id]`, String(dimension_value_id));
          } else {
            formData.append(`${typeModelMap[cdType][0]}[${typeModelMap[cdType][1]}_attributes][${key}][dimension_value_assignables_attributes][${i}][_destroy]`, "1");
          }
          formData.append(`${typeModelMap[cdType][0]}[${typeModelMap[cdType][1]}_attributes][${key}][dimension_value_assignables_attributes][${i}][id]`, Boolean(id) ? String(id) : "");
        }
      }
    }
  }, [])


  useEffect(() => {
    const ParentForm: HTMLFormElement = document.querySelector(`.cd-main-form`);

    if(!ParentForm) return;

    ParentForm.addEventListener('formdata', parentFormSubmitHandler);

    return () => ParentForm.removeEventListener('formdata', parentFormSubmitHandler);

  }, [])

  const setUpRowDimension = (e: CustomEvent) => {
    const {id, action} = e.detail;
    const dimensionIcon = document.getElementById(`${id}`)?.querySelector('.line_item_dimension');

    if(!dimensionIcon) return;
    
    if(action === 'ADD') {
      dimensionIcon.addEventListener('click', showModal);
      dataRef.current.set(id, []);
    } else if(action === 'MOUNT') {
      dimensionIcon.addEventListener('click', showModal);
      if(!dataRef.current.has(id)) {
        dataRef.current.set(id, []); 
      }
    } else if(action === 'REMOVE') {
      dimensionIcon.removeEventListener('click', showModal);
      dataRef.current.delete(id);
    }
  }

  useEffect(() => {
    document.addEventListener('updateCdDimension', setUpRowDimension);
    
    return () => {
      document.removeEventListener('updateCdDimension', setUpRowDimension);
    }
  }, [])

  useEffect(() => {
    (async() => {
      try {
        if(!Boolean(cdID)) return;
        let { data } = await axios.get(`/tenant/dimension_value_assignables.json?q[for_cd_id]=${cdID}&q[for_cd_type]=${cdType}&q[with_active_dimension_values]=true&per_page=2000`);
        
        if(data.pagination.total_entries > 2000) {
          data = (await axios.get(`/tenant/dimension_value_assignables.json?q[for_cd_id]=${cdID}&q[for_cd_type]=${cdType}&q[with_active_dimension_values]=true&per_page=${data.pagination.total_entries}`)).data;
        }
        
        for(const record of data.records) {
          const tr = document.querySelector(`tr[data-id="${record.external_line_item_id}"]`)
          const trIdx = tr.id;
          if(Boolean(record.dimension_values.length)) {
            tr.querySelector('.line_item_dimension').classList.add('line-item-has-applied')
          }
          dataRef.current.set(trIdx, record.dimension_values)
        }
      } catch(e) {}
    })()
  }, [])
  
  const showModal = async (e: Event) => {
    const target = e.target as HTMLDivElement;
    const parentTR = target?.closest('tr')
    
    if(!parentTR) return;
    
    const id = parentTR.id;
    setIsModalOpen(true);
    const queryStr = cdID ? `q[available_for_cd_id]=${cdID}` : `q[available_for_cd_type]=${cdType}`
    const { data } = await axios.get(`/tenant/dimensions.json?${queryStr}`);
    activeDimensionList.current = data.records;
    activeLineItem.current = id;
    formObservable.next({dimensions: data.records, trID: id});
  };

  const handleOk = () => {
    let isConfirmApply = false;
    let count = 0;
    for(const [isApplyAll] of tmpAppliedRef.current.values()) {
      if(isApplyAll) {
        isConfirmApply = true;
        ++count;
      }
    }
    if(isConfirmApply) {
      modal.confirm({
        title: t('activerecord.attributes.dimension_value.line_item_apply'),
        content: t('activerecord.attributes.dimension_value.line_item_confirm', {count}),
        onOk: () => {
          handleApply();
        },
        okText: t('forms.buttons.confirm'),
        cancelText: t('forms.buttons.cancel')
      })
    } else {
      handleApply();
    }
  };

  const handleApply = () => {
    if(Boolean(activeDimensionList.current.length) && activeLineItem.current) {      
      updateApplyList();

      const refObj = dataRef.current.get(activeLineItem.current)
      if(Boolean(refObj)) {
        const tr = document.getElementById(activeLineItem.current);
        const dimensionLi = tr?.querySelector('.line_item_dimension')
        const tmp = [];
        const formValues = Object.values(form.getFieldsValue());
        for(let i = 0; i < activeDimensionList.current.length; i++) {
          const dimension = activeDimensionList.current[i];
          const dimensionRef = refObj.find(item => item.dimension_id === dimension.id);
          tmp.push({id: dimensionRef?.id, dimension_id: dimension.id, dimension_value_id: formValues[i]})
        }
        console.log(tmp);
        
        if(Boolean(tmp.filter(entry => Boolean(entry.dimension_value_id)).length)) {
          dimensionLi.classList.add('line-item-has-applied');
        } else {
          dimensionLi.classList.remove('line-item-has-applied');
        }
        dataRef.current.set(activeLineItem.current, tmp);
      }
    }
    setIsModalOpen(false);
  }

  const handleCancel = () => {
    activeDimensionList.current = [];
    activeLineItem.current = null;
    tmpAppliedRef.current = new Map();
    setIsModalOpen(false);
  };

  return (
    <Modal width={'60vw'} title={t("activerecord.attributes.dimension.name_plural")} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <ModalContext.Provider value={{dataRef: dataRef.current, appliedRef: appliedRef.current, updateTmpApplyList}} >
        <Form form={form} />
      </ModalContext.Provider>
      {modalContext}
    </Modal>
  );
}

export default DimesnionModal