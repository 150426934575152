import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { SorterResult } from "antd/es/table/interface";

const notIncludedFilterKeys = ['page', 'per_page', 'q[s]']

const SORT_DIRECTION = new Map([['ascend', 'asc'], ['descend', 'desc']])
const SORT_DIRECTION_REVERSE = new Map([['asc', 'ascend'], ['desc', 'descend']])
interface InteractiveFilterProps {
  setFilterUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  path: string;
  shouldUpdateURL?: boolean
  trasnfromSearchParams?: (search: URLSearchParams) => URLSearchParams
}

export const useInteractiveFilter = ({ setFilterUpdate, path, shouldUpdateURL = true, trasnfromSearchParams = (s) => s }: InteractiveFilterProps, depArray) => {
  
  const [url, setURL] = useState(new URL(window.location.href));
  const [page, setPage] = useState(parseInt(url.searchParams.get('page')) || 1);
  const [pageSize, setPageSize] = useState(parseInt(url.searchParams.get('per_page')) || 15);
  const [sorter, setSorter] = useState<SorterResult<any>>(null);
  const [filterApplied, setFilterApplied] = useState(false);
  const [sorterStr, setSorterStr] = useState(url.searchParams.get('q[s]') ?? '');
  const [defaultSorted, setDefaultSorted] = useState(sorterStr? true : false)
  const [queryParams, setQueryParams] = useState({});
  const [ formContainerRef, setFormContainerRef ] = useState(null);
  const [ form ] = Form.useForm();

  useEffect(() => {
    const preObject = {};
    [['page', page], ['per_page', pageSize], ['q[s]', sorterStr]].forEach(([key, value]) => {
      if(value) {
        preObject[key] = value;
      }
    })
    setQueryParams(preObject);
    setFilterUpdate(state => !state);

  }, [page, pageSize, sorterStr])
  
  useEffect(() => {
    if(sorter?.field && sorter?.order) {
      setDefaultSorted(false)
      setSorterStr(`${sorter.field} ${SORT_DIRECTION.get(sorter.order)}`)
    } else if(!defaultSorted) {
      setSorterStr('')
    }
  }, [sorter])
  
  useEffect(() => {
    if(formContainerRef) {   
      for(const [key, value] of url.searchParams.entries()) {
        form.setFieldValue(key, value);
      }
    }    
  }, [formContainerRef])

  useEffect(() => {
    for(const key of url.searchParams.keys()) {
      if(!notIncludedFilterKeys.includes(key) && url.searchParams.get(key)) {
        setFilterApplied(true);
        break;
      }
    }
  }, [])
  
  useEffect(() => {
    if(formContainerRef) {
      const formFields = {};
      for(const [key, value] of Object.entries(form.getFieldsValue())) {
          if(value) {
            formFields[key] = value;
          }
      }

      if(Object.keys(formFields).length > 0) {
        setFilterApplied(true);
      } else {
        setFilterApplied(false);
      }
      
      const search = trasnfromSearchParams(
        new URLSearchParams({ ...queryParams, ...formFields })
      );
      setURL(prevURL => {
        const newURL = new URL(prevURL);
        newURL.search = search.toString();
        return newURL;
      })
    }
  }, [...depArray, formContainerRef])

  useEffect(() => {
    if (shouldUpdateURL) history.pushState({}, '', url);
  }, [url])
  
  return {
    url: `${path}${url.search}`,
    page: {
      get: () => page,
      set: setPage
    },
    pageSize: {
      get: () => pageSize,
      set: setPageSize
    },
    sorter: {
      get: () => {
        const sortSplit = sorterStr.split(' ');
        if(sorterStr && sortSplit.length === 2) {
          const field = sortSplit[0];
          const order = SORT_DIRECTION_REVERSE.get(sortSplit[1]);
          if(field && order) {
            return {field, order};
          }
        }
      },
      set: setSorter
    },
    update: () => {
      setPage(1);
      setFilterUpdate(state => !state)
    },
    form,
    setFormContainerRef,
    filterApplied
  };
}